<template>
  <q-dialog v-model="isShow" persistent>
    <q-card :class="{ 'create-document': true, extended }">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">Créer un nouveau document</div>
      </q-card-section>

      <q-card-section>
        <div class="form-container">
          <q-form @submit="onSubmit" class="q-gutter-md">
            <div :class="{ extended }">
              <div class="left">
                <q-input
                  class="name"
                  counter
                  :maxlength="maxLengthName"
                  square
                  outlined
                  bottom-slots
                  v-model="name"
                  placeholder="Nommez le document"
                  type="text"
                  @input.native="e => checkName(e)"
                  lazy-rules
                  :rules="[
                    val =>
                      (val && val.trim().length > 0) ||
                      'Veuillez nommer le document'
                  ]"
                >
                  <template v-slot:hint>
                    Nom du document
                  </template>
                </q-input>

                <div class="top">
                  <div>Choisir le modèle</div>
                  <p>
                    Sélectionnez le modèle que vous souhaitez utiliser pour
                    créer ce document
                  </p>
                </div>

                <Search
                  placeholder="Rechercher un modèle"
                  @setSearchText="setSearchText"
                  :global="false"
                />

                <div class="radio-wrapper" v-if="listModels.length">
                  <q-radio
                    class="item"
                    v-for="item in listModels"
                    :key="item.modelId"
                    v-model="type"
                    :val="item.modelId"
                    :label="item.modelName"
                  />
                </div>

                <div v-else>Aucun modèle crée</div>
              </div>

              <div class="right" v-if="extended">
                <div class="top">
                  <div>Choisir un projet</div>
                  <p>
                    Choisissez le projet pour lequel vous souhaitez créer ce
                    document
                  </p>
                </div>

                <Search
                  placeholder="Rechercher un projet"
                  @setSearchText="setSearchTextProject"
                  :global="false"
                />

                <div class="radio-wrapper" v-if="listProjects.length">
                  <q-radio
                    class="item"
                    v-for="item in listProjects"
                    :key="item.projectId"
                    v-model="typeProject"
                    :val="item.projectId"
                    :label="item.projectName"
                  />
                </div>

                <div v-else>Aucun projet crée</div>
              </div>
            </div>

            <div class="bottom-buttons">
              <q-btn label="Annuler" class="btn-cancel" @click="closeModal" />

              <q-btn label="Sauvegarder" class="btn-save" type="submit">
                <q-icon class="save-icon" />
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import Search from '@/components/global/Search.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateDocumentModal',
  components: {
    Search
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    extended: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      searchProject: '',
      type: '',
      typeProject: '',
      listModels: [],
      listProjects: [],
      name: '',
      maxLengthName: 100
    }
  },
  computed: {
    ...mapGetters('documents', [
      'getModels',
      'getLoadingModels',
      'getErrorModels',
      'getErrorSendingDocument'
    ]),
    ...mapGetters('projects', [
      'getAllProjects',
      'getLoadingAllProjects',
      'getErrorAllProjects'
    ])
  },
  async created() {
    await this.fetchModels()
    this.listModels = this.getModels

    if (this.extended) {
      await this.fetchAllProjects()
      this.listProjects = this.getAllProjects
    }

    if (!this.listModels.length && this.extended && !this.listProjects.length) {
      this.setIsActiveCreateDocument({ status: false })
    } else {
      this.setIsActiveCreateDocument({ status: true })
    }
  },
  methods: {
    ...mapActions('documents', [
      'fetchModels',
      'sendDocument',
      'setIsActiveCreateDocument',
      'fetchLastDocuments',
      'fetchDocuments',
      'fetchDocumentsByProjectId'
    ]),
    ...mapActions('projects', ['fetchAllProjects']),
    closeModal() {
      this.$emit('closeModal')
    },
    setSearchText(val) {
      this.search = val
      this.listModels = this.getModels.filter(
        item => item.modelName.toLowerCase().indexOf(val.toLowerCase()) > -1
      )
    },
    setSearchTextProject(val) {
      this.searchProject = val
      this.listProjects = this.getAllProjects.filter(
        item => item.projectName.toLowerCase().indexOf(val.toLowerCase()) > -1
      )
    },
    resetValue() {
      this.name = ''
      this.type = ''
      this.typeProject = ''
    },
    async onSubmit() {
      const { projectId } = this.$route.params
      if (!this.typeProject && projectId) {
        this.typeProject = projectId
      }

      if (!this.type) {
        this.showNotify('Veuillez choisir un modèle')
        return
      }

      if (!this.typeProject) {
        this.showNotify('Veuillez choisir un projet')
        return
      }

      const data = {
        reportName: this.name.trim(),
        modelId: this.type,
        projectId: this.typeProject
      }

      const { reportId } = await this.sendDocument(data)
      if (this.getErrorSendingDocument.length) return

      this.$emit('closeModal')
      this.resetValue()

      this.$router.push(`/document/${reportId}`)
      // this.showNotify('Document created', true)

      // switch (this.$route.name) {
      //   case 'Home':
      //     this.fetchLastDocuments()
      //     break
      //   case 'Project':
      //     this.fetchDocumentsByProjectId({ projectId })
      //     break
      //   case 'Documents':
      //     this.fetchLastDocuments()
      //     this.fetchDocuments()
      //     break
      //   default:
      //     break
      // }
    },
    showNotify(message, empty = false) {
      if (empty) {
        const {
          colorEmpty,
          iconEmpty,
          timeout,
          position
        } = this.$q.config.alert
        this.$q.notify({ colorEmpty, iconEmpty, message, position, timeout })
      } else {
        const { color, icon, timeout, position } = this.$q.config.alert
        this.$q.notify({ color, icon, message, position, timeout })
      }
    },
    checkName(e) {
      if (!e.data?.trim().length && !this.name.trim().length) this.name = ''
    }
  },
  watch: {
    extended: function(value) {
      this.extended = value
    },
    getErrorModels: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorAllProjects: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorSendingDocument: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
